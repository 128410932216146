// i18n
import translation from 'locales/nl-NL/translation';
import common from 'locales/nl-NL/common';
import dateLocale from 'date-fns/locale/nl';
import i18next from './facades/i18next';

// i18n for dates
import dateFns from './facades/dateFns';

i18next.init({
  lng: 'nl-NL',
  translation,
  common,
});

dateFns.init({
  locale: dateLocale,
});
