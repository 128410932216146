// Runtime webpack config
import 'other/WebpackConfig';

// i18n
import 'i18n/nl-NL';

// Because some translations are done immediately on module import
// we need to import page only after translation engine is initialized
import LoginAdmarkt from './LoginAdmarkt';

new LoginAdmarkt({ name: 'LoginAdmarkt' });
